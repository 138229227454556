<template>
  <div :class="['loading-wrapper', position]">
    <h3>{{ text }}</h3>
    <img class="spinner" src="@/assets/icons/spinner.gif" alt="Loading" />
  </div>
</template>

<script>
export default {
  name: "loading",
  props: {
    text: {
      type: String,
      required: false,
      default: "Loading",
    },
    position: {
      type: String,
      default: "top",
      required: false,
      validator(value) {
        return ["top", "center"].includes(value);
      },
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.loading-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 24px;
  &.center {
    margin-top: 25%;
    margin-bottom: 25%;
  }
  &.top {
    margin: 0;
  }
  h3 {
    margin: 0px;
  }
  .spinner {
    height: 64px;
    padding: 0;
    width: 64px;
  }
}
</style>
